import React, { createContext, useContext, useState } from "react";

const PostContext = createContext();

const PostProvider = ({ children }) => {
	const [postTypeId, setPostTypeId] = useState(null);
	const [postToCreate, setPostToCreate] = useState(null);
	// Return the context provider with the value

	return (
		<PostContext.Provider
			value={{
				postTypeId,
				setPostTypeId,
				postToCreate,
				setPostToCreate,
			}}
		>
			{children}
		</PostContext.Provider>
	);
};

export function usePostContext() {
	return useContext(PostContext);
}

export { PostContext, PostProvider };
