import clsx from "clsx";
import documents_treat from "includes/documents_treat";
import genRequest from "includes/request";
import DocIconDownloader from "Pages/Documents/components/DocIconDownloader/DocIconDownloader";
import { useQuery } from "react-query";
import "./DocumentsButtonBottomBar.css";

function DocumentsButton({ postId, toggleDocsExtend, documents, isMobile }) {
    const fetchDocs = () => {
        let req = genRequest("Documents/DocumentOfPostAndType/" + postId + "/4")
            .then((resp) => documents_treat(resp, "PublishedDate"));
        return (req);
    };

    const { isLoading, data } = useQuery(
        ["Documents", "DocumentOfPostAndType", postId, 4],
        fetchDocs,
        {
            enabled: (postId && !documents ? true : false)
        }
    );


    let count = documents ? documents?.length : data?.length;

    if ((!postId && !documents) || isLoading || (!isLoading && !count))
        return (false);

    return (
        <div className={clsx(isMobile ? "docMobile" : "")}>
            {!isLoading && data.map(doc => (
                <>
                    {/* <FontAwesomeIcon icon={faFilePdf} className="me-2" /> */}
                    <DocIconDownloader key={doc.DocumentId} doc={doc} />

                </>
            ))}
            {/* <Button className="post-docs-btn btn-primary radius-3" onClick={toggleDocsExtend} title={t("Posts.DISPLAY_DOCS")}>
				<FontAwesomeIcon icon={faFilePdf} className="me-2" />
				{count} {t("Posts.DOCS_BTN", { count })}
			</Button> */}
        </div>
    );
}

export default DocumentsButton;